<template>
  <div :class="wrapperClasses" class="e-overall">
    <transition name="canvas">
      <div v-show="!offcanvasOpen" class="e-canvas">
        <div v-if="!userInfo || !userInfo.id" class="e-canvas__background" />

        <TheHeader />

        <div class="e-content login-landing">
          <TheSidebar />
          <VuePerfectScrollbar class="width-full">
            <div class="flex gap-2 teaser-container">
              <EmailTeaserBox />
              <ChatEventsTeaserBox
                :chat-events="sortedUpcomingChatEvents.slice(0, 3)"
                :current-chat-event="currentChatEvent"
              />
              <CounselingEventsTeaserBox
                v-if="counselingEventsEnabled"
                :counseling-events="counselingEvents.slice(0, 3)"
                :logged-in="true"
                :total-count="counselingEventTotalCount"
              />
            </div>
          </VuePerfectScrollbar>
        </div>

        <el-dialog
          v-if="announcement && announcement.title && announcement.active"
          :title="announcement.title"
          :visible.sync="currentInfoVisible"
          :before-close="setMessageRead"
          custom-class="el-dialog--small"
        >
          <div v-html="$sanitize(announcement.text)" />
          <span slot="footer" class="dialog-footer">
            <el-button type="tertiary" @click="setMessageRead">{{
              $t('system.understood')
            }}</el-button>
          </span>
        </el-dialog>

        <TheFooter :hide-logos="true" />
      </div>
    </transition>

    <transition name="offcanvas">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <ChatIdleConfirm />
    <color-picker v-if="showColorPicker" />
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'
import deLocale from 'date-fns/locale/de'

import { mapGetters } from 'vuex'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheSidebar from 'molecules/TheSidebar/TheSidebar'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import ChatIdleConfirm from 'organisms/ChatIdleConfirm/ChatIdleConfirm'
import ColorPicker from 'atoms/ColorPicker/ColorPicker'
import EmailTeaserBox from '../../organisms/EMailTeaserBox/EmailTeaserBox.vue'
import ChatEventsTeaserBox from '../../organisms/ChatEventsTeaserBox/ChatEventsTeaserBox.vue'
import CounselingEventsTeaserBox from '../../organisms/CounselingEventsTeaserBox/CounselingEventsTeaserBox.vue'

export default {
  components: {
    ChatIdleConfirm,
    TheFooter,
    TheHeader,
    TheSidebar,
    TheOffcanvas,
    VuePerfectScrollbar,
    ColorPicker,
    EmailTeaserBox,
    ChatEventsTeaserBox,
    CounselingEventsTeaserBox
  },

  data: () => ({
    currentInfoVisible: false,
    loading: false,
    showColorPicker: false
  }),

  computed: {
    ...mapGetters([
      'announcement',
      'chatIsOpen',
      'currentChatEvent',
      'userInfo',
      'offcanvasOpen',
      'upcomingChatEvents',
      'counselingEvents',
      'counselingEventsEnabled',
      'counselingEventTotalCount'
    ]),
    chatText() {
      return this.chatIsOpen
        ? window.landingTexts.chatOpen +
            '<br>Thema: ' +
            ` ${this.currentChatEvent.subject}`
        : window.landingTexts.chatClosed
    },
    sortedUpcomingChatEvents() {
      return [...this.upcomingChatEvents].sort(
        (a, b) => new Date(a.beginning) - new Date(b.beginning)
      )
    },
    wrapperClasses() {
      let classes = {
        'e-overall--loggedin': this.userInfo && this.userInfo.id
      }
      return classes
    }
  },

  created() {
    this.showColorPicker = window.location.search.includes('color_picker=1')
  },

  mounted() {
    if (!this.$cookie.get('messageRead')) {
      this.currentInfoVisible = true
    }
  },

  methods: {
    setMessageRead() {
      this.$cookie.set('messageRead', true)
      this.currentInfoVisible = false
    },
    format(date, token) {
      return format(parseISO(date), token, {
        locale: deLocale
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/LoginLanding';
</style>
