<template>
  <teaser-box
    :header-title="$t('chat.emailSupport')"
    class="e-email-teaser-box"
  >
    <div class="flex flex-column align-center height-full">
      <div class="teaser-box-icon">
        <span class="teaser-box-icon__icon e-mobile-hidden" role="presentation">
          <icon-mailsupport class="e-email-teaser-box__icon" />
        </span>
        <p class="teaser-box-icon__text" v-html="mailText">
        </p>
      </div>
      <router-link
        class="e-btn e-btn--tertiary mt-2"
        :to="dialogRoute"
        >{{ $t('system.navigation.mailSupport') }}</router-link
      >
    </div>
  </teaser-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TeaserBox from 'molecules/TeaserBox/TeaserBox'
import IconMailsupport from 'assets/icons/email-user.svg'
export default {
  name: 'EMailTeaserBox',
  components: {
    TeaserBox,
    IconMailsupport
  },
  computed: {
    ...mapGetters([
      'dialogs'
    ]),
    dialogRoute() {
      if (!this.dialogs.length) {
        return {
          name: 'dialogs'
        }
      }
      return {
        name: 'dialog',
        params:{
          dialogId: this.dialogs[0].id
        }
      }
    },
    mailText() {
      return window.landingTexts.email
    },
  },
  async created() {
    this.getDialogs()
  },
  methods: {
    ...mapActions([
      'getDialogs',
    ])
  }
}
</script>
<style lang="scss" scoped>
.teaser-box-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #808080;
  &__icon {
    $size: 5rem;
    $color: #c7c7c7;
    height: $size;
    font-size: $size;
    color: $color;
    fill: $color;
    margin-bottom: 1rem;
    .user {
      fill: c('primary');
    }
  }

  &__text {
    font-size: 0.75rem;
    max-width: 15rem;
    text-align: center;
  }
}

.e-email-teaser-box {
  & &__icon {
    display: flex;
    height: 100%;
  }
}

.icon {
  width: 1rem;
}
</style>
