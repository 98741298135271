<template>
  <div class="e-color-picker">
    <input :value="hue" type="number" @input="debouncedInput" />
    <div
      class="e-color-picker__slider"
      ref="slider"
      @mousedown.prevent.stop="startDrag"
      @mouseup.prevent.stop="stopDrag"
      @mousemove="handleMove"
    >
      <span
        class="e-color-picker__slider-handle"
        :style="`left: ${sliderLeft}%`"
      />
    </div>
  </div>
</template>

<script>
import { setColorsFromHue } from '../../../shared/color/index'
import debounce from 'lodash/debounce'

export default {
  name: 'ColorPicker',
  data() {
    return {
      isDragging: false,
      sliderLeft: 0,
      hue: 118
    }
  },
  mounted() {},
  watch: {
    hue(newVal) {
      setColorsFromHue(newVal)
    }
  },
  methods: {
    startDrag(evt) {
      this.setPosition(evt.x)
      this.isDragging = true
    },
    stopDrag() {
      this.isDragging = false
    },
    handleMove(evt) {
      if (!this.isDragging) {
        return
      }
      this.setPosition(evt.x)
    },
    setPosition(x) {
      if (!x) {
        return
      }
      let left = x - this.$refs.slider.getBoundingClientRect().x
      left = Math.max(0, left)
      left = (left / this.$refs.slider.offsetWidth) * 100
      left = Math.min(left, 100)
      this.sliderLeft = left
      const hue = (left / 100) * 360
      this.hue = hue.toFixed(1)
    },
    debouncedInput: debounce(function(evt) {
      this.handleHueInput(evt)
    }, 500),
    handleHueInput(evt) {
      let hue = parseInt(evt.target.value)
      if (Number.isNaN(hue)) {
        return
      }
      if (hue < 0) {
        hue = -hue
      }
      hue = Math.min(hue, 360)
      hue = Math.max(0, hue)
      this.hue = hue
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/ColorPicker';
</style>
