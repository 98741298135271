<template>
  <teaser-box
    :header-title="$t('chat.navigation')"
    class="e-chat-events-teaser"
  >
    <div class="teaser-box-icon">
      <span class="teaser-box-icon__icon e-mobile-hidden" role="presentation">
        <icon-chat-user
          class="e-chat-events-teaser__icon"
          :class="{ 'e-chat-events-teaser__icon--active': !!currentChatEvent }"
        />
      </span>
      <p class="teaser-box-icon__text">
        <span v-if="currentChatEvent" v-html="$sanitize(chatOpenText)" />
        <span
          v-else-if="noUpcomingChatEvents"
          v-html="$sanitize(noUpcomingChatEventsText)"
        />
        <span v-else-if="!currentChatEvent" v-html="$sanitize(chatClosedText)" />
      </p>
    </div>
    <teaser-list class="mt-2 mb-2" :events="mappedEvents" />
    <router-link
      class="e-btn e-btn--tertiary mt-auto"
      :class="{ 'is-disabled': !currentChatEvent }"
      :to="{ name: 'liveChat' }"
    >
      <span class="flex align-center">
        <span v-text="$t('chat.toNavigation')" />
      </span>
    </router-link>
  </teaser-box>
</template>

<script>
import TeaserBox from 'molecules/TeaserBox/TeaserBox'
import TeaserList from '../../molecules/TeaserList/TeaserList.vue'
import IconChatUser from 'assets/icons/chat-user.svg'
export default {
  name: 'ChatEventsTeaserBox',
  components: { TeaserBox, TeaserList, IconChatUser },
  props: {
    chatEvents: {
      type: Array
    },
    currentChatEvent: {
      type: Object
    }
  },
  computed: {
    mappedEvents() {
      const events = this.currentChatEvent
        ? [this.currentChatEvent]
        : this.chatEvents
      return events.map(this.toListItem)
    },
    chatOpenText() {
      return window.landingTexts.chatOpen
    },
    chatClosedText() {
      return window.landingTexts.chatClosed
    },
    noUpcomingChatEventsText() {
      return window.landingTexts.noUpcomingChatEvents
    },
    noUpcomingChatEvents() {
      return this.mappedEvents.length === 0
    }
  },
  methods: {
    toListItem(chatEvent) {
      return {
        beginning: chatEvent.beginning,
        end: chatEvent.end,
        subject: chatEvent.subject
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.teaser-box-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #808080;
  &__icon {
    $size: 5rem;
    $color: #c7c7c7;
    height: $size;
    font-size: $size;
    color: $color;
    fill: $color;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 0.75rem;
  }
}

.e-chat-events-teaser {
  .teaser-list:last-child {
    margin-bottom: 0;
  }
  & &__icon {
    display: flex;
    justify-content: center;
    height: 100%;
    &--active .user {
      fill: c('primary');
    }
  }
}
</style>
